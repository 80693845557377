import HighlightModule from 'src/components/HighlightModule/HighlightModule'
import { Box, Flex } from 'src/components/UI/Grid/Grid'

import styles from './ModuleHighlights.module.scss'

import type { ContentfulModule, Highlight, Maybe } from 'src/apollo/types'

const ModuleHighlights = ({ highlights }: ContentfulModule) => {
  const columns = highlights ? highlights.length : 0
  return (
    <Flex
      as="section"
      flexDirection={['column', null, null, 'row']}
      className={styles.section}
    >
      {highlights &&
        highlights.map((highlight: Maybe<Highlight>, index: number) =>
          highlight ? (
            <Box width={[1, null, null, 1 / columns]} key={index}>
              <HighlightModule size={columns} highlight={highlight} />
            </Box>
          ) : null
        )}
    </Flex>
  )
}

export default ModuleHighlights
