import { INLINES } from '@contentful/rich-text-types'
import { useState } from 'react'

import LinkComponent from 'src/components/UI/LinkComponent/LinkComponent'
import RichText from 'src/components/RichText/RichText'
import { Box, Flex } from 'src/components/UI/Grid/Grid'

import styles from './ModuleQuote.module.scss'

import type { ContentfulModule } from 'src/apollo/types'

const ModuleQuote = ({ title, link, text }: ContentfulModule) => {
  const [visible, setVisible] = useState(false)
  const observeHandler = (entries: any) => {
    for (const entry of entries) {
      if (entry.isIntersecting) {
        setVisible(true)
      } else {
        setVisible(false)
      }
    }
  }
  const observer = process.browser
    ? new IntersectionObserver(observeHandler, {
        rootMargin: '0px 0px 0px 0px',
      })
    : null
  // let boldIndex = 0
  const richtextOptions: any = {
    // renderMark: {
    //   [MARKS.ITALIC]: (text: string) => {
    //     boldIndex++
    //     return (
    //       <span>
    //         <div style={{ transitionDelay: `${boldIndex * 0.2}s` }}>{text}</div>
    //       </span>
    //     )
    //   },
    // },
    renderNode: {
      [INLINES.HYPERLINK]: (node: any) => {
        const { uri } = node.data
        const value =
          node && node.content && node.content[0] && node.content[0].value

        return uri.startsWith('http') ? (
          <a href={uri}>{value}</a>
        ) : (
          <LinkComponent href={uri}>{value}</LinkComponent>
        )
      },
    },
  }

  const jsontext = JSON.parse(text ? text.text : '')
  if (link && link.slug) {
    const readMoreLink = {
      content: [
        {
          data: {},
          marks: [],
          nodeType: 'text',
          value: link.title,
        },
      ],
      data: { uri: link.slug },
      nodeType: 'hyperlink',
    }
    jsontext.content[0].content.push(readMoreLink)
  }

  return (
    <Flex
      className={styles.wrapper}
      flexDirection="column"
      paddingLeft={[5, null, null, 14]}
      paddingRight={[5, null, null, 7]}
      paddingTop={[18, null, null, 12]}
      paddingBottom={[20, null, null, 28]}
    >
      {/* <Box as="h3" className={styles.title}>
        {title}
      </Box> */}
      <Box
        className={
          visible ? [styles.text, styles.active].join(' ') : styles.text
        }
        ref={(x) => {
          if (x && observer) {
            observer.observe(x)
          }
        }}
        width={[1, null, null, '70%']}
        marginTop={[null, null, null, 14]}
      >
        {text && text.text && (
          <RichText
            options={richtextOptions}
            text={JSON.stringify(jsontext)}
          ></RichText>
        )}
      </Box>
    </Flex>
  )
}

export default ModuleQuote
