import Marquee from 'react-fast-marquee'

import Image from 'src/components/UI/Image/Image'
import { Box } from 'src/components/UI/Grid/Grid'

import styles from './ModuleLogoSlider.module.scss'

import type { ContentfulModule, Maybe, Logos } from 'src/apollo/types'

const ModuleLogoSlider = ({ logos }: ContentfulModule) => {
  return (
    <Box paddingY={[16, null, null, 20]} className={styles.marquee}>
      <Marquee gradient={false}>
        {logos &&
          logos.map((logo: Maybe<Logos>) =>
            logo && logo.url ? (
              <a
                href={logo.link ? logo.link : ''}
                target="_blank"
                rel="noopener noreferrer"
                key={logo.id}
              >
                <Image src={logo.url} alt={logo.title ? logo.title : ''} />
              </a>
            ) : null
          )}
        {logos &&
          logos.map((logo: Maybe<Logos>) =>
            logo && logo.url ? (
              <a
                href={logo.link ? logo.link : ''}
                target="_blank"
                rel="noopener noreferrer"
                key={`${logo.id}-2`}
              >
                <Image src={logo.url} alt={logo.title ? logo.title : ''} />
              </a>
            ) : null
          )}
      </Marquee>
    </Box>
  )
}

export default ModuleLogoSlider
