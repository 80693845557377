import { useState } from 'react'

import Image from 'src/components/UI/Image/Image'
import LinkComponent from 'src/components/UI/LinkComponent/LinkComponent'
import Video from 'src/components/Video/Video'
import { Box, Flex } from 'src/components/UI/Grid/Grid'
import { getContentfulSrcSet } from 'src/utils/image'

import styles from './HighlightModule.module.scss'

import type { Highlight } from 'src/apollo/types'

const LinkWrapper = ({ slug, children }: any) =>
  slug ? <LinkComponent href={slug}>{children}</LinkComponent> : children

const HighlightModule = ({
  highlight,
  size,
}: {
  highlight: Highlight
  size: number
}) => {
  const [visible, setVisible] = useState(false)
  const observeHandler = (entries: any) => {
    for (const entry of entries) {
      if (entry.isIntersecting) {
        setVisible(true)
      } else {
        setVisible(false)
      }
    }
  }
  const observer = process.browser
    ? new IntersectionObserver(observeHandler, {
        rootMargin: '0px 0px 0px 0px',
      })
    : null

  const { image, link, title, textPosition, backgroundColor } = highlight

  const classNames = [styles.wrapper]
  const position =
    size === 1 && textPosition ? textPosition.split('/')[0] : null

  if (size > 1) {
    classNames.push(styles.medium)

    if (size > 2) {
      classNames.push(styles.small)
    }
  }
  // if (darkText === 'true') {
  //   classNames.push(styles.dark)
  // }

  const textDivs = title ? title.split(' ') : []

  return (
    <LinkWrapper className={styles.link} slug={link && link.slug}>
      <Box
        bg={backgroundColor}
        paddingTop={[
          '133.3%',
          null,
          null,
          size === 1 ? '56.25%' : size === 2 ? '125%' : '133.3%',
        ]}
        className={classNames.join(' ')}
        color={image && image.url ? '#ffffff' : '#111111'}
      >
        {image &&
          image.url &&
          image.contentType &&
          (image.contentType.includes('video') ? (
            <Video video={image} isMuted={true} title={title ? title : ''} />
          ) : (
            <Image
              srcSet={getContentfulSrcSet(image.url)}
              alt={image.title ? image.title : ''}
            />
          ))}
        <Flex
          paddingX={[5, null, null, 14]}
          paddingY={[7, null, null, size === 1 ? 14 : 12]}
          className={
            visible
              ? [styles.content, styles.visible].join(' ')
              : styles.content
          }
          flexDirection="column"
          justifyContent={position === 'center' ? 'center' : ['flex-end']}
          alignItems={position === 'center' ? 'center' : ['flex-start']}
        >
          <Flex
            flexDirection={size === 1 ? 'column' : 'row'}
            width={1}
            textAlign={position === 'center' ? 'center' : 'left'}
            alignItems={
              position === 'center'
                ? 'center'
                : size === 1
                ? 'flex-start'
                : 'flex-end'
            }
            justifyContent={[size === 1 ? 'flex-start' : 'space-between']}
          >
            <Flex
              flexWrap="wrap"
              marginBottom={size === 1 ? [3] : 0}
              width={size === 1 ? [1, null, null, 1 / 2] : 1}
              className={styles.title}
              as="h3"
              ref={(x) => {
                if (x && observer) {
                  observer.observe(x)
                }
              }}
            >
              {textDivs &&
                textDivs.map((text: string, index: number) => (
                  <Box
                    className={styles.textItem}
                    marginRight={'0.21em'}
                    paddingX={'0.01em'}
                    as="span"
                    key={index}
                  >
                    <span style={{ transitionDelay: `${index * 0.12}s` }}>
                      {text}
                    </span>
                  </Box>
                ))}
            </Flex>
            {link && link.title && link.slug && (
              <Box as="span" className={styles.cta}>
                {link.title}
              </Box>
            )}
          </Flex>
        </Flex>
      </Box>
    </LinkWrapper>
  )
}

export default HighlightModule
