import React, { Component } from 'react'
import isNode from 'is-node'
import { FormattedMessage } from 'react-intl'

import styles from './Video.module.scss'
import { Props } from './types'

export default class Video extends Component<Props> {
  videoRef: any
  observer: any = null
  constructor(props: any) {
    super(props)
    if (!isNode) {
      this.observer = new IntersectionObserver(this.observeHandler, {
        rootMargin: '0px 0px 200px 0px',
      })
    }
  }

  observeHandler = (entries: any) => {
    for (const entry of entries) {
      if (entry.isIntersecting) {
        if (this.videoRef) {
          this.videoRef.play()
        }
        this.observer.unobserve(entry.target)
      }
    }
  }

  render() {
    const { video, title, className, isMuted } = this.props
    const { url } = video
    const classNames = [styles.wrapper]
    if (className) {
      classNames.push(className)
    }

    if (url) {
      return (
        <div className={classNames.join(' ')}>
          <video
            className={styles.video}
            // poster={poster}
            muted={isMuted}
            loop
            title={title}
            playsInline
            ref={(x) => {
              if (x) {
                this.observer.observe(x)
                this.videoRef = x
              }
            }}
          >
            <source src={url} type="video/mp4" />
            <FormattedMessage
              id="v/CX/p"
              defaultMessage="Your browser does not support the video tag."
            />
          </video>
        </div>
      )
    }
  }
}
