import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

const RichText = ({
  text,
  options,
}: {
  text: string
  options?: any
}): JSX.Element | null => {
  if (!text) {
    return null
  }

  return <>{documentToReactComponents(JSON.parse(text), options)}</>
}

export default RichText
